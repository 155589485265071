var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-manage-article","title":"Manage article","no-close-on-backdrop":"","size":"xl"},on:{"ok":_vm.onOk}},[_c('validation-observer',{ref:"cmsArticleForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null},model:{value:(_vm.articleForm.title),callback:function ($$v) {_vm.$set(_vm.articleForm, "title", $$v)},expression:"articleForm.title"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"cover_image_src","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cover Image"}},[_c('CmsFileSelect',{attrs:{"id":"cover_image_src","value":_vm.articleForm.coverImageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.articleForm.coverImageSrc = value || ''); }}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"image_src","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image"}},[_c('CmsFileSelect',{attrs:{"id":"image_src","value":_vm.articleForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.articleForm.imageSrc = value || ''); }}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"short_description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Short description"}},[_c('b-form-textarea',{attrs:{"id":"image_src","v-model":_vm.articleForm.shortDescription,"state":errors[0] ? false : null,"placeholder":"Short description of this article","rows":"3","max-rows":"6"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{attrs:{"id":"image_src","v-model":_vm.articleForm.description,"state":errors[0] ? false : null,"placeholder":"Description of this article","rows":"3","max-rows":"6"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"content","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Content"}},[_c('vue-editor',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.articleForm.content),callback:function ($$v) {_vm.$set(_vm.articleForm, "content", $$v)},expression:"articleForm.content"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Slug"}},[_c('b-form-input',{attrs:{"id":"slug","placeholder":"how-to-get-rich (no space between text)","state":errors[0] ? false : null},model:{value:(_vm.articleForm.slug),callback:function ($$v) {_vm.$set(_vm.articleForm, "slug", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"articleForm.slug"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"author","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Author"}},[_c('b-form-input',{attrs:{"id":"author","placeholder":"Jon snow","state":errors[0] ? false : null},model:{value:(_vm.articleForm.author),callback:function ($$v) {_vm.$set(_vm.articleForm, "author", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"articleForm.author"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position"}},[_c('b-form-input',{attrs:{"id":"position","state":errors[0] ? false : null},model:{value:(_vm.articleForm.position),callback:function ($$v) {_vm.$set(_vm.articleForm, "position", $$v)},expression:"articleForm.position"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }