<template>
  <b-modal
    id="cms-manage-article"
    title="Manage article"
    no-close-on-backdrop
    size="xl"
    @ok="onOk"
  >
    <validation-observer
      ref="cmsArticleForm"
    >
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="title"
          rules=""
        >
          <b-form-group label="Title">
            <b-form-input
              id="title"
              v-model="articleForm.title"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="cover_image_src"
          rules="required"
        >
          <b-form-group label="Cover Image">
            <CmsFileSelect
              id="cover_image_src"
              :value="articleForm.coverImageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (articleForm.coverImageSrc = value || '')"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="image_src"
          rules=""
        >
          <b-form-group label="Image">
            <CmsFileSelect
              id="image_src"
              :value="articleForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (articleForm.imageSrc = value || '')"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="short_description"
          rules=""
        >
          <b-form-group label="Short description">
            <b-form-textarea
              id="image_src"
              :v-model="articleForm.shortDescription"
              :state="errors[0] ? false : null"
              placeholder="Short description of this article"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="description"
          rules=""
        >
          <b-form-group label="Description">
            <b-form-textarea
              id="image_src"
              :v-model="articleForm.description"
              :state="errors[0] ? false : null"
              placeholder="Description of this article"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="content"
          rules=""
        >
          <b-form-group label="Content">
            <vue-editor
              v-model="articleForm.content"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="slug"
          rules="required"
        >
          <b-form-group label="Slug">
            <b-form-input
              id="slug"
              v-model.trim="articleForm.slug"
              placeholder="how-to-get-rich (no space between text)"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="author"
          rules=""
        >
          <b-form-group label="Author">
            <b-form-input
              id="author"
              v-model.trim="articleForm.author"
              placeholder="Jon snow"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="position"
          rules="required"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="articleForm.position"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
import { required } from '@validations'

export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
    VueEditor,
  },
  data() {
    return {
      required,

      articleForm: {
        position: 0,
        title: '',
        imageSrc: '',
        coverImageSrc: '',
        shortDescription: '',
        description: '',
        content: '',
        author: '',
        slug: '',
      },
    }
  },
  computed: {
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsArticle']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-manage-article'))
      }
    },
    async onSubmit(cb) {
      this.$refs.cmsArticleForm.validate().then( async (success) => {
        if (success) {
           await this.createCmsArticle({
            ...this.articleForm,
            cmsSiteId: this.siteId,
          })

          cb()
        }
      })
     
    },
  },
}
</script>
